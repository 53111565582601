export default function normalizeText(text: string): string {
  return text.trim().toLowerCase();
}

interface WordForms {
  singular: string; // Именительный падеж, единственное число (например, "товар")
  few: string; // Родительный падеж, единственное число (например, "товара")
  many: string; // Родительный падеж, множественное число (например, "товаров")
}

export function getLabel(count: number, wordForms: WordForms): string {
  if (count % 100 >= 11 && count % 100 <= 19) {
    return `${count} ${wordForms.many}`;
  }

  const lastDigit = count % 10;

  if (lastDigit === 1) {
    return `${count} ${wordForms.singular}`;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return `${count} ${wordForms.few}`;
  } else {
    return `${count} ${wordForms.many}`;
  }
}
