import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Stack,
  Divider,
  List,
  ListItem,
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  IconButton,
} from "@mui/material";
import "./AppMenu.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { usePWAInstall } from "react-use-pwa-install";

import config from "../../appConfig/config";
import familyImage from "../../assets/img/family.png";
import logo from "../../assets/img/logo.svg";
import { User } from "../../services/openapi";
import { Paths } from "../../utils/constants";
import { InstallButton } from "../InstallButton/InstallButton";
import { UserLogoName } from "../UserLogoName/UserLogoName";

export interface AppMenuProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  user: User | null;
  onSignOutClick: () => void;
  onAddListClick: () => void;
}

export const AppMenu = (props: AppMenuProps) => {
  const handleCreateListClick = () => {
    props.onAddListClick();
    props.onClose();
  };
  const navigate = useNavigate();
  const install = usePWAInstall() as (() => Promise<boolean> | null) | null;

  return (
    <SwipeableDrawer
      className="menu-drawer"
      anchor="left"
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
    >
      <Box className="menu-drawer-header">
        <Box className="menu-drawer-header-logo" component="img" src={logo} alt="logo" />
      </Box>
      <Box className="menu-drawer-image">
        <Box component="img" alt="Listy image" src={familyImage} />
      </Box>
      <Stack className="menu-drawer-login">
        {props.user !== null && props.user.name ? (
          <UserLogoName
            userLogoUrl={props.user.profileUrl || ""}
            userName={props.user.name}
          />
        ) : (
          <NavLink to={Paths.LOGIN_HOME} className="menu-drawer-list-link">
            <Button
              className="menu-drawer-list-button"
              variant="text"
              color="secondary"
              startIcon={<AccountCircleOutlinedIcon />}
            >
              <Typography>Войти</Typography>
            </Button>
          </NavLink>
        )}
      </Stack>
      <Box className="menu-drawer-divider-container">
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          className={"menu-drawer-close " + (!props.open ? "active" : "")}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Divider />
      </Box>

      <List className="menu-drawer-list">
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={handleCreateListClick}
          >
            <Typography>Создать новый список</Typography>
          </Button>
        </ListItem>
        {/*<ListItem>*/}
        {/*  <Button*/}
        {/*    className="menu-drawer-list-button"*/}
        {/*    variant="text"*/}
        {/*    color="secondary"*/}
        {/*    startIcon={<StarBorderOutlinedIcon />}*/}
        {/*    onClick={console.log}*/}
        {/*  >*/}
        {/*    <Typography>PRO-версия</Typography>*/}
        {/*  </Button>*/}
        {/*</ListItem>*/}
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<EmailOutlinedIcon />}
            onClick={() => navigate(Paths.SUPPORT)}
          >
            <Typography>Обратная связь</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<SettingsOutlinedIcon />}
            onClick={() => navigate(Paths.SETTINGS)}
          >
            <Typography>Настройки</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<PlaylistAddRoundedIcon />}
            onClick={() => navigate(Paths.FUTURE_FEATURES)}
          >
            <Typography>Будущие функции</Typography>
          </Button>
        </ListItem>
        {/*<ListItem>*/}
        {/*  <Button*/}
        {/*    className="menu-drawer-list-button"*/}
        {/*    variant="text"*/}
        {/*    color="secondary"*/}
        {/*    startIcon={<HelpOutlineOutlinedIcon />}*/}
        {/*    onClick={console.log}*/}
        {/*  >*/}
        {/*    <Typography>Помощь</Typography>*/}
        {/*  </Button>*/}
        {/*</ListItem>*/}
        {props.user && (
          <ListItem>
            <Button
              className="menu-drawer-list-button"
              variant="text"
              color="secondary"
              startIcon={<LogoutOutlinedIcon />}
              onClick={props.onSignOutClick}
            >
              <Typography>Выйти</Typography>
            </Button>
          </ListItem>
        )}
      </List>
      {install && (
        <Box className="menu-drawer-install-button-container">
          <InstallButton onClick={install} occasion={config.Occasion}>
            Установить приложение
          </InstallButton>
        </Box>
      )}
    </SwipeableDrawer>
  );
};
