export const RadioChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="7.9375" fill="#F8FAFC" stroke="#E1E9EF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.3334C14.6024 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6024 1.66669 10 1.66669C5.39765 1.66669 1.66669 5.39765 1.66669 10C1.66669 14.6024 5.39765 18.3334 10 18.3334ZM8.63521 13.7287L14.8889 7.4815V7.48243C14.9535 7.41798 15.0047 7.34145 15.0397 7.2572C15.0747 7.17296 15.0928 7.08264 15.0928 6.99142C15.0929 6.90019 15.075 6.80984 15.0402 6.72553C15.0054 6.64122 14.9543 6.56459 14.8898 6.50002C14.8254 6.43545 14.7489 6.38421 14.6646 6.34922C14.5804 6.31423 14.49 6.29618 14.3988 6.29609C14.3076 6.29601 14.2173 6.31389 14.1329 6.34872C14.0486 6.38355 13.972 6.43465 13.9074 6.49909L8.14539 12.2556L6.09261 10.2037C5.96097 10.0811 5.78685 10.0143 5.60694 10.0175C5.42704 10.0206 5.25538 10.0935 5.12815 10.2207C5.00091 10.348 4.92803 10.5196 4.92486 10.6995C4.92168 10.8794 4.98846 11.0536 5.11113 11.1852L7.65372 13.7287C7.78393 13.8588 7.96044 13.9318 8.14447 13.9318C8.32849 13.9318 8.505 13.8588 8.63521 13.7287Z"
        fill="#08875D"
      />
    </svg>
  );
};
