import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../../services/internalStorage/models/ListItemInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { ListDetailedDto, ListsService } from "../../services/openapi";
import { convertServerListItemCategoryToLocal } from "../../services/sync/services/SyncListsService";
import { SyncService } from "../../services/sync/services/SyncService";
import {
  COMMON_STORE_KEYS,
  LIST_EXAMPLE_NAME,
  LIST_ITEMS_EXAMPLE_NAMES,
} from "../../utils/constants";
import { getUniqueId } from "../../utils/dateTimeUtil";
import normalizeText from "../../utils/normalizeText";
import { addPrompt, updatePrompt } from "../prompts/promptsSlice";
import { RootState } from "../store";

export type ListsStateType = {
  lists: ListInternalModel[];
  listItems: ListItemInternalModel[];
  selectedList: ListInternalModel | null;
  shareListKeyForSharing: string | null;
  shareListKeyForAccept: string | null;
  listToAccept: ListDetailedDto | null;
  areListsLoading: boolean;
  areListItemsLoading: boolean;
};

const initialState: ListsStateType = {
  lists: [],
  listItems: [],
  selectedList: null,
  shareListKeyForSharing: null,
  shareListKeyForAccept: null,
  listToAccept: null,
  areListsLoading: true,
  areListItemsLoading: true,
};

export const fetchLists = createAsyncThunk<
  { lists: ListInternalModel[]; selectedList: ListInternalModel | null } | null,
  void
>("lists/fetchLists", async () => {
  try {
    const lists = (await InternalStorageListsService.getLists())
      .filter((l) => !l.deleted)
      .sort((a, b) => a.order - b.order);
    let selectedList: ListInternalModel | null;
    const savedSelectedListLocalId = (await InternalStorageCommonService.getValue(
      COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
    )) as number | undefined;
    const savedSelectedList = lists.find((l) => l.localId === savedSelectedListLocalId);
    if (savedSelectedList) {
      selectedList = { ...savedSelectedList };
    } else if (lists.length) {
      selectedList = lists[0];
    } else {
      selectedList = null;
    }
    return { lists, selectedList };
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const fetchListItems = createAsyncThunk<Array<ListItemInternalModel>, number>(
  "lists/fetchListItems",
  async (listId) => {
    try {
      return await InternalStorageListsService.getListItems(listId);
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const createList = createAsyncThunk<ListInternalModel | null, string>(
  "lists/createList",
  async (name) => {
    try {
      const currentDateISO = new Date().toISOString();
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        currentDateISO,
      );
      const createdList = await InternalStorageListsService.createList({
        name,
        id: null,
        localId: getUniqueId(),
        owner: { name: "", id: "" },
        order: 0,
        created: currentDateISO,
        updated: currentDateISO,
        deleted: null,
      });
      SyncService.enqueue();
      return createdList;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const removeList = createAsyncThunk<number | null, number>(
  "lists/removeList",
  async (listId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      const deletedListId = await InternalStorageListsService.deleteList(listId, true);
      SyncService.enqueue();
      return deletedListId;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const saveSelectedList = createAsyncThunk<void, number>(
  "lists/saveSelectedList",
  async (listLocalId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
        listLocalId,
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const createListItem = createAsyncThunk<
  ListItemInternalModel | null,
  {
    name: string;
    localListId: number;
    localCategory: ListItemCategoryInternalModel | null;
  }
>("lists/createListItem", async ({ name, localListId, localCategory }) => {
  try {
    const currentDateISO = new Date().toISOString();
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    const createdListItem = await InternalStorageListsService.createListItem({
      name: name,
      id: null,
      localId: getUniqueId(),
      localListId: localListId,
      isCompleted: false,
      created: currentDateISO,
      updated: currentDateISO,
      order: 0,
      deleted: null,
      localCategory,
    });
    SyncService.enqueue();
    return createdListItem;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateListItem = createAsyncThunk<void, ListItemUpdateParams>(
  "lists/updateListItem",
  async (listItemUpdateParams, { dispatch }) => {
    try {
      const updatedListItem =
        await InternalStorageListsService.updateListItem(listItemUpdateParams);
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      SyncService.enqueue();

      dispatch(fetchListItems(updatedListItem.localListId));
    } catch (e) {
      console.log(e);
    }
  },
);
export const deleteCategoryFromListItems = createAsyncThunk<void, number>(
  "lists/updateListCategories",
  async (categoryId) => {
    const listItems = await InternalStorageListsService.getAllListItems();
    const updatedListaItems: Promise<ListItemInternalModel>[] = [];
    listItems.forEach((listItem) => {
      if (listItem.localCategory?.localId == categoryId) {
        const updatedListItem = InternalStorageListsService.updateListItem({
          localId: listItem.localId,
          localCategory: null,
        });
        updatedListaItems.push(updatedListItem);
      }
    });
    await Promise.all(updatedListaItems);
  },
);
export const changeListItemCategory = createAsyncThunk<
  void,
  { listItemLocalId: number; category: ListItemCategoryInternalModel | null }
>(
  "lists/changeListItemCategory",
  async ({ listItemLocalId, category }, { dispatch, getState }) => {
    try {
      dispatch(
        updateListItem({
          localId: listItemLocalId,
          localCategory: category,
        }),
      );
      const state = getState() as RootState;
      const listItem = state.lists.listItems.find(
        (listItem) => listItem.localId === listItemLocalId,
      );
      if (category && listItem) {
        const prompt = state.prompts.prompts.find(
          (prompt) => listItem.name === prompt.name,
        );
        if (prompt) {
          dispatch(
            updatePrompt({
              ...prompt,
              localCategoryId: category.localId,
              updated: new Date().toISOString(),
            }),
          );
        } else {
          dispatch(addPrompt({ name: listItem.name, localCategoryId: category.localId }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteListItems = createAsyncThunk<
  number[] | null,
  { listItemsToDelete: Array<ListItemInternalModel> }
>("lists/deleteListItems", async ({ listItemsToDelete }) => {
  try {
    const deleteRequests = listItemsToDelete.map((listItem) =>
      InternalStorageListsService.deleteListItem(listItem.localId, true),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    const deletedItemsIds = await Promise.all(deleteRequests);
    SyncService.enqueue();
    return deletedItemsIds;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const getShareListKey = createAsyncThunk<string | null, number>(
  "lists/getShareListKey",
  async (listId) => {
    try {
      return await ListsService.getApiListsShareKey(listId);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const getListByShareKey = createAsyncThunk<
  {
    listToAccept: ListDetailedDto | null;
    existingList: ListInternalModel | null;
    shareKey: string | null;
  },
  string
>("lists/getListByShareKey", async (shareKey) => {
  try {
    const listToAccept = await ListsService.getApiListsShareKey1(shareKey);
    const existingList = (await InternalStorageListsService.getLists()).find(
      (list) => list.id === listToAccept.id,
    );
    if (existingList) {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
        existingList.localId,
      );
      return { listToAccept: null, existingList, shareKey };
    }
    return { listToAccept, existingList: null, shareKey };
  } catch (e) {
    console.log(e);
    return { listToAccept: null, existingList: null, shareKey: null };
  }
});

export const acceptSharedList = createAsyncThunk<
  ListInternalModel | null,
  {
    listToAccept: ListDetailedDto;
    shareListKeyForAccept: string;
    signedIn: boolean;
  }
>("lists/acceptSharedList", async ({ listToAccept, shareListKeyForAccept, signedIn }) => {
  try {
    if (signedIn) {
      const syncAndCheckSharedListExistence = async () => {
        await SyncService.enqueue();
        const existingList = (await InternalStorageListsService.getLists()).find(
          (list) => list.id === listToAccept.id,
        );
        if (existingList) {
          await InternalStorageCommonService.addOrUpdateValue(
            COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
            existingList.localId,
          );
          return existingList;
        }
        return null;
      };

      const existingList = await syncAndCheckSharedListExistence();
      if (existingList) {
        return existingList;
      }
      await ListsService.postApiListsShared({ sharedListKey: shareListKeyForAccept });
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      const acceptedList = await syncAndCheckSharedListExistence();
      if (acceptedList) {
        return acceptedList;
      }
      return null;
    }
    const acceptedList = await InternalStorageListsService.createList({
      name: listToAccept.name,
      id: listToAccept.id,
      localId: getUniqueId(),
      owner: listToAccept.owner ?? { name: "", id: "" },
      order: listToAccept.order,
      created: listToAccept.created ?? new Date().toISOString(),
      updated: listToAccept.updated ?? new Date().toISOString(),
      deleted: null,
      shareKey: shareListKeyForAccept,
    });
    if (listToAccept.items?.length) {
      const localCategories = await InternalStorageCategoriesService.getCategories();
      await Promise.all(
        listToAccept.items.map(async (serverListItem) => {
          const localCategory = localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverListItem.category?.id,
          );
          await InternalStorageListsService.createListItem({
            name: serverListItem.name,
            id: serverListItem.id,
            localId: getUniqueId(),
            localListId: acceptedList.localId,
            isCompleted: serverListItem.isCompleted,
            created: serverListItem.created || new Date().toISOString(),
            updated: serverListItem.updated || new Date().toISOString(),
            order: Infinity,
            deleted: null,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
          });
        }),
      );
    }
    return acceptedList;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const deleteAllListsAndItems = createAsyncThunk<void, void>(
  "lists/deleteAllListsAndItems",
  async () => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      await InternalStorageListsService.deleteAllListsAndItems();
    } catch (e) {
      console.log(e);
    }
  },
);

export const copyListItems = createAsyncThunk<
  void,
  { listItems: ListItemInternalModel[]; listLocalId: number }
>("lists/transferListItems", async ({ listItems, listLocalId }) => {
  try {
    const currentDateISO = new Date().toISOString();
    await Promise.all(
      listItems.map((listItem) =>
        InternalStorageListsService.createListItem({
          ...listItem,
          id: null,
          localId: getUniqueId(),
          localListId: listLocalId,
          created: currentDateISO,
          updated: currentDateISO,
        }),
      ),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
  } catch (e) {
    console.log(e);
  }
});

export const createListExample = createAsyncThunk<
  void,
  { prompts: ListItemPromptInternalModel[]; categories: ListItemCategoryInternalModel[] }
>("lists/createListExample", async ({ prompts, categories }, { dispatch }) => {
  try {
    const createdList = await InternalStorageListsService.createList({
      name: LIST_EXAMPLE_NAME,
      id: null,
      localId: getUniqueId(),
      owner: { name: "", id: "" },
      order: 0,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      deleted: null,
    });
    await Promise.all(
      LIST_ITEMS_EXAMPLE_NAMES.map(async (name) => {
        const prompt = prompts.find((p) => normalizeText(p.name) === normalizeText(name));
        const category = categories.find((c) => c.localId === prompt?.localCategoryId);
        await InternalStorageListsService.createListItem({
          name,
          id: null,
          localId: getUniqueId(),
          localListId: createdList.localId,
          isCompleted: false,
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          order: 0,
          deleted: null,
          localCategory: category ?? null,
        });
      }),
    );
    dispatch(fetchLists());
  } catch (e) {
    console.log(e);
  }
});

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    selectList(state, action: { payload: { localId: number } }) {
      const newSelectedList = state.lists.find(
        (l) => l.localId === action.payload.localId,
      );
      if (newSelectedList) {
        state.selectedList = { ...newSelectedList };
      }
    },
    setShareListKeyForAccept(state, action: { payload: string | null }) {
      state.shareListKeyForAccept = action.payload;
    },
    setShareListKeyForSharing(state, action: { payload: string | null }) {
      state.shareListKeyForSharing = action.payload;
    },
    setListToAccept(state, action: { payload: ListDetailedDto | null }) {
      state.listToAccept = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLists.fulfilled, (state, action) => {
      if (action.payload) {
        state.lists = action.payload.lists;
        state.selectedList = action.payload.selectedList;
      } else {
        state.lists = [];
        state.selectedList = null;
        state.listItems = [];
      }
      state.areListsLoading = false;
    });
    builder.addCase(fetchListItems.fulfilled, (state, action) => {
      state.listItems = action.payload.filter((li) => li.deleted === null);
      state.areListItemsLoading = false;
    });
    builder.addCase(createList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(removeList.fulfilled, (state, action) => {
      state.lists = state.lists.filter((list) => list.localId !== action.payload);
      state.selectedList = state.lists.length ? state.lists[0] : null;
      state.listItems = [];
    });
    builder.addCase(createListItem.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listItems.push(action.payload);
      }
    });
    builder.addCase(deleteListItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const idsSet = new Set(action.payload);
        state.listItems = state.listItems.filter(
          (listItem) => !idsSet.has(listItem.localId),
        );
      }
    });
    builder.addCase(getShareListKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.shareListKeyForSharing = action.payload;
      }
    });
    builder.addCase(getListByShareKey.fulfilled, (state, action) => {
      const { existingList, listToAccept, shareKey } = action.payload;
      if (existingList) {
        state.selectedList = existingList;
        state.listToAccept = null;
        state.shareListKeyForAccept = null;
      } else if (listToAccept) {
        state.listToAccept = listToAccept;
        state.shareListKeyForAccept = shareKey;
      } else {
        state.listToAccept = null;
        state.shareListKeyForAccept = null;
      }
    });
    builder.addCase(acceptSharedList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(deleteAllListsAndItems.fulfilled, (state) => {
      state.lists = [];
      state.listItems = [];
      state.selectedList = null;
    });
  },
});

export const {
  selectList,
  setShareListKeyForAccept,
  setShareListKeyForSharing,
  setListToAccept,
} = listsSlice.actions;
