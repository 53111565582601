import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import "./TransferItemsDialog.scss";
import { useState } from "react";

import { RadioChecked } from "../../../assets/Icons/RadioChecked";
import { RadioUnchecked } from "../../../assets/Icons/RadioUnchecked";
import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { getLabel } from "../../../utils/normalizeText";

export interface TransferItemsDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (listLocalId: number) => void;
  itemsCount: number;
  lists: ListInternalModel[];
}

export const TransferItemsDialog = (props: TransferItemsDialogProps) => {
  const [checkedListLocalId, setCheckedListLocalId] = useState<number | null>(null);

  const handleClose = () => {
    props.onClose();
    setCheckedListLocalId(null);
  };

  const handleConfirm = () => {
    if (checkedListLocalId) {
      props.onConfirm(checkedListLocalId);
      handleClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="transfer-items-dialog">
      <DialogTitle className="transfer-items-dialog-title">Перенести товары</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="transfer-items-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="transfer-items-dialog-content">
        {!props.itemsCount ? (
          <Typography className="transfer-items-dialog-content-text">
            Нет товаров для переноса
          </Typography>
        ) : (
          <>
            <Typography className="transfer-items-dialog-content-text">
              Выберите список, куда перенести{" "}
              {getLabel(props.itemsCount, {
                singular: "товар",
                few: "товара",
                many: "товаров",
              })}
            </Typography>
            <FormControl className="transfer-items-dialog-content-form">
              <RadioGroup>
                {props.lists.map((list) => (
                  <FormControlLabel
                    key={list.id}
                    value={list.id}
                    checked={list.localId === checkedListLocalId}
                    onClick={() => setCheckedListLocalId(list.localId)}
                    control={
                      <Radio checkedIcon={<RadioChecked />} icon={<RadioUnchecked />} />
                    }
                    label={list.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions className="transfer-items-dialog-actions">
        <Button
          onClick={handleConfirm}
          variant="contained"
          className="transfer-items-dialog-actions-confirm"
          disabled={!props.itemsCount || !checkedListLocalId}
        >
          Принять
        </Button>
      </DialogActions>
    </Dialog>
  );
};
